import { createApp } from 'vue';
import App from './App.vue';
//import { VDatePicker } from 'vuetify/labs/VDatePicker';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const app = createApp(App);

const vuetify = createVuetify({
    components: {
        ...components,
    //    VDatePicker
    },
    directives,
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                colors: {
                    primary: '#546E7A',
                    secondary: '#52ab98',
                    accent: '#CFD8DC',
                    info: '#f2f2f2',
                }
            },
            dark: {
                colors: {
                    primary: '#90A4AE',
                    secondary: '#B2DFDB',
                    accent: '#CFD8DC',
                    info: '#f2f2f2',
                }
            }
        }
    }
});

app.use(vuetify);
app.mount('#app');
